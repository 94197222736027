import React from 'react'
import {Layout, Stack, Hero, Main, Sidebar, PreFooter} from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import {useBlogTags, useBlogCategories} from '@helpers-blog'
import BannerVertical from "@widgets/BannerVertical";

const Posts = ({data: {paginatedPosts = {}, featuredPosts = {}}, ...props}) => {
    const {pageContext: {services = {}, basePath} = {}} = props
    const tags = useBlogTags()
    const categories = useBlogCategories()

    return (
        <Layout {...props}>
            <Seo title='Home'/>
            <Divider/>
            <Stack effectProps={{effect: false}}>
                <Categories categories={categories} variant='horizontal' omitTitle/>
            </Stack>
            {/*<Hero sx={{ bg: `contentBg` }}>*/}
            {/*  <HeroComponent {...props} />*/}
            {/*</Hero>*/}


            <Divider/>
            <Stack>
                <Main>
                    <CardList
                        nodes={featuredPosts.nodes}
                        limit={1}
                        variant={['vertical', 'horizontal-lg']}
                        title='Suggested'

                        loading='eager'
                    />
                    <Divider/>

                    <CardList
                        variant={['horizontal-md', 'horizontal']}
                        title='Recently Published'
                        nodes={paginatedPosts.nodes.filter(paginatedPost => !featuredPosts.nodes.find(featuredPost => featuredPost.id === paginatedPost.id))}
                        columns={[1]}
                    />
                </Main>
                <Sidebar>
                    {/*<Categories categories={categories} />*/}
                    {/*<Divider />*/}
                    {/*<BannerVertical />*/}
                    {/*<Divider />*/}
                    <Tags tags={tags}/>
                    <Divider/>
                    <Social/>
                    <Sticky>
                        {services.mailchimp && (
                            <>
                                <Divider/>
                                <NewsletterCompact/>
                            </>
                        )}
                    </Sticky>
                </Sidebar>
            </Stack>
            <Divider/>
            <PreFooter>
                <Pagination {...paginatedPosts.pageInfo} basePath={basePath}/>
            </PreFooter>
        </Layout>
    )
}

export default Posts
